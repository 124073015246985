/*
in order to use css module, filename extension must be .module.css
this module is only used for ProductList component
using css module can prevent the class names in this module conflict with those in other modules

using <link> to link css file is also prefered
browser can cache those linked css files, so the webpage will be loaded faster next time
*/

.containerItem{
    border: 3px solid #f7f7f7;
    border-radius: 7px;
    width: 300px;
    margin: 5px;
    text-align: center;
    background-color: antiquewhite;
    padding: 10px 10px;
}

.containerItem:hover{
    background-color: rgb(221, 255, 228);
}
